.messages {
    --message-margin: 2rem;
    --bubble-color: #FFFFFF;
    --radius: 16px;
    flex: 1 1 1px;
    position: absolute;
    top: 56px;
    width: 100%;
    height: calc(100% - 56px);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 20px;
    font-family: "Nunito Regular", sans-serif;
}

.messages .message {
    color: #4A586E;
    margin-top: calc(var(--message-margin) / 2);
    margin-bottom: calc(var(--message-margin) / 2);
    margin-right: 65px;
    margin-left: 65px;
    width: calc(100% - 100px);
    border-radius: var(--radius);
    position: relative;
    background: var(--bubble-color);
}

.container-message.start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1rem;
}

.container-message.end {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-content: flex-start;
    margin-bottom: 1rem;
}

.container-bubbles.start {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    max-width: 87%;
}

.container-bubbles.end {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    max-width: 87%;
}

.messages .message.first {
    margin-top: var(--message-margin);
}

.messages .message.last {
    margin-bottom: calc(var(--message-margin) * 1.5);
}

.messages .message.right {
    color: #FFF;
    margin-bottom: 0;
    margin-right: var(--radius);
    border-top-right-radius: 0;
    padding: 12px;
    width: 87%;
}

.messages .message.left {
    background: var(--bubble-color);
    margin-bottom: 0;
    margin-left: var(--radius);
    border-top-left-radius: 0;
    padding: 12px;
    width: 87%;
}

.messages .message.left.error {
    --bubble-color: var(--mdc-theme-error, #D43551);
    color: #FFF;
}

.messages .message img {
    height: auto;
    width: 100%;
    max-width: 100%;
}

.messages .message audio {
    height: 42px;
    max-width: 100%;
}

.messages .message p {
    line-height: 20px;
    margin: 0;
}

.messages .message small {
    font-size: 10px;
}

.date-container {
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 0;
    margin-top: 4px;
    font-size: 10px;
    line-height: 14px;
}

.network-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loader {
    width: 40px;
    aspect-ratio: 4;
    --_g: no-repeat radial-gradient(circle closest-side, #4A586E 90%, #0000);
    background:
        var(--_g) 0% 50%,
        var(--_g) 50% 50%,
        var(--_g) 100% 50%;
    background-size: calc(100%/3) 100%;
    animation: l7 1s infinite linear;
}

.spinner {
    border: 3px solid #FFFFFF;
    border-left-color: transparent;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes l7 {
    33% {
        background-size: calc(100%/3) 0%, calc(100%/3) 100%, calc(100%/3) 100%
    }

    50% {
        background-size: calc(100%/3) 100%, calc(100%/3) 0%, calc(100%/3) 100%
    }

    66% {
        background-size: calc(100%/3) 100%, calc(100%/3) 100%, calc(100%/3) 0%
    }
}

@media (max-width: 768px) {
    .container-bubbles.start {
        min-width: calc(100% - 35px);
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
    
    .container-bubbles.end {
        min-width: calc(100% - 35px);
        display: flex;
        align-items: flex-end;
        flex-direction: column;
    }
}