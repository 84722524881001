.container {
    top: 68px;
    right: 24px;
    position: fixed;
    color: #000000;
    z-index: 2;
    width: 450px;
    min-height: 790px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 6px #00000040;
}

.in {
    transition: opacity 0.5s;
    animation-name: fadeIn;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.out {
    transition: opacity 0.5s;
    animation-name: fadeOut;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.content {
    display: flex;
    flex-direction: column;
    padding: 28px 24px 24px;
}

.card-top {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.close {
    font-size: 16px;
    line-height: 20px;
    font-family: "Roboto Condensed", sans-serif;
    cursor: pointer;
    position: absolute;
    right: 25px;
}

.input-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.select {
    width: 130px;
    height: 51px;
    position: absolute;
}

.container-input {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 402px;
    font-size: 16px;
    line-height: 19px;
    font-family: "Roboto Condensed", sans-serif;
}

.w-input-container {
    width: 100%;
    min-height: 48px;
    box-sizing: border-box;
    padding: 15px 38px 14px 140px;
    background-color: transparent;
    border: 1px solid #BCBCBC;
    border-radius: 8px;
}

.on-input {
    border: 1px solid #002559;
}

.w-input-text-group {
    position: relative;
}

.w-placeholder {
    top: 0;
    color: #BCBCBC;
    user-select: none;
    position: absolute;
    pointer-events: none;
    transition: 0.2s padding ease-in-out;
}

.w-input-text {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    height: 20px;
    outline: 0;
    transition: 0.2s padding ease-in-out;
}

#w-input-text:empty+.w-placeholder {
    opacity: 1;
}

#w-input-text:focus+.w-placeholder {
    padding: 0 6px 0px;
}

#w-input-text:focus {
    padding: 0 6px 0px;
}

.dates-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fade-out {
    animation: fade-out 0.5s ease;
}

.fade-in {
    animation: fade-in 0.5s ease;
}

@media (max-width: 425px) {
    .container {
        right: 14px;
        width: calc(100vw - 28px);
        max-height: calc(100vh - 150px);
    }
}