.recording-button {
    position: absolute;
    width: 44px;
    height: 44px;
    background: #002559;
    border-radius: 50%;
    border: 1px solid #002559;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s ease-in;
    background-image: url("../../assets/icons/mdi-mic-white.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 25px;
}

.pulse {
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% {
        width: 44px;
        height: 44px;
    }

    33% {
        width: 74px;
        height: 74px;
    }

    66% {
        width: 84px;
        height: 84px;
    }

    100% {
        width: 44px;
        height: 44px;
    }
}