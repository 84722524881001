.picker-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.date-label {
    color: #000000 !important;
    font-size: 14px;
    line-height: 17px;
    font-family: "Roboto Condensed Bold", sans-serif;
}

.react-datepicker-wrapper {
    padding: 0px !important;
    width: 189px !important;
}

.react-datepicker__day-name {
    color: #000000 !important;
    margin: 0 !important;
    width: 48px !important;
    height: 48px !important;
    line-height: 20px;
    font-size: 14px !important;
    font-weight: unset !important;
    font-family: "Ubuntu Bold", sans-serif;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text {
    color: #000000 !important;
    margin: 0 !important;
    padding: 16px !important;
    width: 48px !important;
    height: 48px !important;
    line-height: 20px;
    font-size: 14px !important;
    font-family: "Ubuntu", sans-serif;
}

.react-datepicker__day {
    box-sizing: border-box;
    padding: 11px !important;
}

.react-datepicker__day {
    padding: 2px 4px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
    background-color: #E2F3E9 !important;
}

.date-picker-input {
    padding: 12px;
    height: 42px;
    max-width: 189px !important;
    color: #000000 !important;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Roboto Condensed', sans-serif;
    background: transparent;
    border: 1px solid #BCBCBC;
    border-radius: 8px;
    box-sizing: border-box;
    cursor: pointer;
    padding-left: 50px;
}

.left {
    background-image: url("../../assets/icons/mdi-fecha-inicio.svg");
    background-position: right;
    background-repeat: no-repeat;
    background-size: 24px;
    background-position-x: 9px !important;
}

.right {
    background-image: url("../../assets/icons/mdi-fecha-fin.svg");
    background-position: right;
    background-repeat: no-repeat;
    background-size: 24px;
    background-position-x: 9px !important;
}

.date-picker-container-left::before {
    content: '';
    position: absolute;
    left: 66px;
    margin-top: 11px;
    width: 1px;
    height: 22px;
    background-color: #BCBCBC;
}

.date-picker-container-right::before {
    content: '';
    position: absolute;
    right: 170px;
    margin-top: 11px;
    width: 1px;
    height: 22px;
    background-color: #BCBCBC;
}

.date-picker-input::placeholder {
    color: #BCBCBC;
    font-size: 14px;
    line-height: 19px;
    font-family: "Roboto Condensed", sans-serif;
}

.date-picker-input.second-selected::placeholder {
    color: #BCBCBC;
    font-size: 16px;
    line-height: 19px;
    font-family: "Roboto Condensed", sans-serif;
}

.date-picker-input:focus {
    border: 1px solid #002559;
}

.date-picker-input:not(:focus) {
    border: 1px solid #BCBCBC;
}

.date-picker-input {
    border: 1px solid #BCBCBC;
}

.date-picker-input.focused {
    border: 1px solid #002559;
}

.react-datepicker__header {
    background-color: #FFFFFF !important;
    border-bottom: 0px !important;
}

.react-datepicker__month-container {
    height: 100%;
    float: none !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    font-weight: unset !important;
    color: #000000 !important;
    font-size: 16px !important;
    line-height: 16px;
    font-family: "Ubuntu Bold", sans-serif;
}

.react-datepicker__day-names {
    margin-top: 15px;
}

.react-datepicker__day--keyboard-selected {
    background-color: #E2F3E9 !important;
    color: #000000 !important;
}

.react-datepicker__day--selected {
    background-color: #E2F3E9 !important;
    color: #000000 !important;
}

.react-datepicker__day--in-selecting-range {
    background-color: #E2F3E9 !important;
    color: #000000 !important;
}

.calendar {
    width: 402px;
    margin-top: -3px;
    padding: 15px 0px;
    box-sizing: border-box;
    border: none !important;
    box-shadow: 0px 3px 6px #00000040;
    background-color: #FFFFFF !important;
}

.right-picker {
    right: 213px;
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker__navigation {
    width: 24px !important;
    height: 24px !important;
    background: #FFFFFF !important;
}

.react-datepicker__navigation--next {
    top: 19px !important;
    right: 95px !important;
    border: none !important;
    background-image: url("../../assets/icons/mdi-mensual-rg1.svg") !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: 24px !important
}

.react-datepicker__navigation--previous {
    top: 19px !important;
    left: 95px !important;
    border: none !important;
    background-image: url("../../assets/icons/mdi-mensual-ig1.svg") !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: 24px !important;
}

.react-datepicker__header {
    height: 65px !important;
}

.date-picker-wrapper::before {
    content: '';
    position: absolute;
    left: 38px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 60%;
    background-color: #BCBCBC;
}

@media (max-width: 425px) {
    .date-picker-input {
        max-width: 145px !important;
    }
    .picker-container {
        max-width: 145px !important;
    }
    .date-picker-container-right::before {
        right: 125px;
    }
}