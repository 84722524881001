.mic-handler {
    right: 8px;
    z-index: 5;
    width: 44px;
    height: 44px;
    cursor: pointer;
    position: absolute;
}

.mic-arrow {
    transform: rotate(180deg);
}

.recording-survey {
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 2.5rem;
    background-color: #002559;
}