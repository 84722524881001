.input-container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    color: #4A586E;
}

.chat-input {
    gap: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: #F3F5F7;
}

.input-area {
    width: 100%;
    padding: 8px;
    padding-right: 0;
    display: flex;
    align-items: center;
    background-color: transparent;
}

.style-oninput {
    resize: none;
    width: 100%;
    min-height: 44px;
    outline: none;
    padding: 11px 16px;
    border-radius: 24px;
    border: 1px solid #002559;
    font-size: 16px !important;
    line-height: 20px;
    background-color: transparent;
    transition: border 0.5s ease;
    font-family: "Nunito Regular", sans-serif;
}

.style-oninput:focus {
    padding-top: 20px;
}

.style-offinput {
    resize: none;
    width: 100%;
    min-height: 44px;
    outline: none;
    padding: 11px 16px;
    border-radius: 24px;
    border: 1px solid #C1C1C1;
    font-size: 16px !important;
    line-height: 20px;
    background-color: transparent;
    transition: border 0.5s ease;
    font-family: "Nunito Regular", sans-serif;
}

.style-offinput::-webkit-input-placeholder {
    color: #C1C1C1;
    font-size: 16px;
    line-height: 20px;
    padding-top: 11px;
}

.microphone-container {
    width: 44px;
    height: 44px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.style-send-button {
    width: 44px;
    height: 44px;
    margin-right: 8px;
    background: #002559;
    border-radius: 50px;
    border: 1px solid #002559;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s ease-in;
    background-image: url("../../assets/icons/mdi-send-white.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    border-radius: 24px;
    background-color: #FFFFFF;
    font-family: "Nunito Regular", sans-serif;
}

.w-input-container {
    width: 100%;
    min-height: 44px;
    box-sizing: border-box;
    padding: 11px 16px;
    background-color: transparent;
    border: 1px solid #C1C1C1;
    border-radius: 24px;
}

.on-input {
    border: 1px solid #C1C1C1;
}

.w-input-text-group {
    position: relative;
}

.w-placeholder {
    top: 0;
    font-size: 16px;
    color: #C1C1C1;
    line-height: 20px;
    user-select: none;
    position: absolute;
    pointer-events: none;
    transition: 0.2s padding ease-in-out;
}

.w-input-text {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 16px;
    line-height: 20px;
    white-space: pre-wrap;
    word-wrap: break-word;
    max-height: 100px;
    min-height: 20px;
    outline: 0;
    color: #4A586E;
    caret-color: #01C5C6;
    transition: 0.2s padding ease-in-out;
}

#w-input-text:empty+.w-placeholder {
    opacity: 1;
}

#w-input-text:focus+.w-placeholder {
    padding: 0 6px 0px;
}

#w-input-text:focus {
    padding: 0 6px 0px;
}