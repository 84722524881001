.container {
    top: 0;
    z-index: 2;
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(22, 22, 22, .5);
}

.card {
    width: 327px;
    height: 284px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
}

.title {
    font-size: 16px;
    line-height: 20px;
    font-family: 'Nunito Bold';
}

.subtitle {
    font-size: 16px;
    line-height: 20px;
    font-family: 'Nunito Regular';
    padding: 0px 42px;
    text-align: center;
}

.thumbs-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.thumbs-up {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60px;
    height: 60px;
    background-image: url('../../assets/icons/mdi-like-gris.svg');
    background-repeat: no-repeat;
    cursor: pointer;
    margin: 0px 12px;
}

.thumbs-down {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60px;
    height: 60px;
    background-image: url('../../assets/icons/mdi-dislike-gris.svg');
    background-repeat: no-repeat;
    cursor: pointer;
    margin: 0px 12px;
}

.thumbs-up:hover {
    color: #002559;
    background-image: url('../../assets/icons/mdi-like-verde.svg');
}

.thumbs-down:hover {
    color: #DD141D;
    background-image: url('../../assets/icons/mdi-dislike-rojo.svg');
}

.qualification {
    font-size: 16px;
    line-height: 20px;
    font-family: 'Nunito Regular';
    padding: 0px 42px;
    text-align: center;
    margin-top: 70px;
}

.check-icon {
    margin: 0;
    padding: 0;
    width: 80px;
    height: 80px;
    background-image: url('../../assets/icons/mdi-ok-gracias.svg');
    background-repeat: no-repeat;
}