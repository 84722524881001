.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Nunito Regular', sans-serif;
}

.header {
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  background-color: #002559;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  font-family: 'Nunito Bold', sans-serif;
}

.actions {
  right: 16px;
  position: fixed;
  gap: 16px;
  display: flex;
  flex-direction: row;
}

.icons {
  width: 24px;
  height: 24px;
}

.search-container {
  width: 91%;
  height: 48px;
  gap: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-radius: 24px;
  border: 1px solid #F6F9FC;
  background-color: #F6F9FC;
}

.form-input {
  height: 48px;
  width: 75%;
  font-size: 16px;
  line-height: 20px;
  color: #C1C1C1;
  border: 1px solid #F6F9FC;
  background-color: #F6F9FC;
  font-family: 'Roboto', sans-serif;
}

.search {
  width: 32px;
  height: 32px;
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.microphone {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #002559;
}

.content {
  padding: 0px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0px 3px 6px #00000029;
}

.description {
  color: #002559;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  font-family: 'Nunito Regular', sans-serif;
}

.buttons {
  gap: 16px;
  display: flex;
  flex-direction: column;
}