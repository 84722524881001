.button {
    padding: 0px 10px;
    width: calc(100% - 48px);
    min-height: 40px;
    font-size: 16px;
    line-height: 20px;
    border-radius: 22px;
    font-family: "Nunito Bold", sans-serif;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
    color: #000000;
}

.red-filled {
    color: #FFFFFF;
    background-color: #DD141D;
    border: 1px solid #DD141D;
}

.red-outlined {
    color: #DD141D;
    background-color: #FFFFFF;
    border: 1px solid #DD141D;
}

.green-filled {
    color: #FFFFFF;
    background-color: #002559;
    border: 1px solid #002559;
}

.green-outlined {
    color: #002559;
    background-color: #FFFFFF;
    border: 1px solid #002559;
}

.blue-filled {
    color: #FFFFFF;
    background-color: #0069FF;
    border: 1px solid #0069FF;
}

.blue-outlined {
    color: #0069FF;
    background-color: #FFFFFF;
    border: 1px solid #0069FF;
}

.skyblue-filled {
    color: #FFFFFF;
    background-color: #01C5C6;
    border: 1px solid #01C5C6;
}

.skyblue-outlined {
    color: #01C5C6;
    background-color: #FFFFFF;
    border: 1px solid #01C5C6;
}

.navy-filled {
    color: #FFFFFF;
    background-color: #002559;
    border: 1px solid #002559;
}

.navy-outlined {
    color: #002559;
    background-color: #FFFFFF;
    border: 1px solid #002559;
}

.white-filled {
    color: #FFFFFF;
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
}

.disabled {
    color: #FFFFFF;
    background-color: #DDDDDD;
    border: 1px solid #DDDDDD;
}

.disabled-filled {
    color: #FFFFFF;
    background-color: #575757;
    border: 1px solid #575757;
}

.icon {
    width: 20px;
    height: 20px;
    margin: 0px 4px;
}