.container {
    display: flex;
    flex-direction: column;
    color: #000000;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 24px;
    height: 100%;
}

.empty-container {
    height: 488px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.no-result-icon {
    width: 48px;
    height: 48px;
}

.item {
    cursor: pointer;
}

.content-container {
    height: 100%;
}

.description-container {
    max-height: 21px;
    overflow: hidden;
}

.dates-container {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Roboto Condensed';
}

.active {
    color: #002559;
}

.bold-text {
    font-size: 16px;
    line-height: 20px;
    font-family: 'Roboto Condensed Bold';
}

.light-text {
    font-size: 16px;
    line-height: 20px;
    font-family: 'Roboto Condensed';
}

.back-button {
    color: #002559;
    border: none;
    background: transparent;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Roboto Condensed';
    cursor: pointer;
    text-align: left;
}

.pagination-container {
    right: 24px;
}