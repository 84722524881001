.select-container {
  height: 44px;
  display: flex;
  position: relative;
  flex-direction: column;
  box-sizing: border-box;
  font-family: 'Nunito Regular', sans-serif;
}

.select-input {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  line-height: 20px;
  padding: 11px 16px;
  border-radius: 24px;
  box-sizing: border-box;
  border: 1px solid #F6F9FC;
  background-color: #F6F9FC;
}

.placeholder {
  color: #C1C1C1;
}

.focused {
  border: 1px solid #01C5C6;
}

.select-options {
  top: 4px;
  gap: 22px;
  display: flex;
  flex-direction: column;
  padding: 11px 16px;
  position: relative;
  border-radius: 24px;
  border: 1px solid #F6F9FC;
  background-color: #F6F9FC;
}

.icon {
  width: 24px;
  height: 24px;
}