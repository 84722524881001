@font-face {
    font-family: "Roboto Bold";
    src: local("RobotoCondensed"),
        url("../src/assets/fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto";
    src: local("Roboto"),
        url("../src/assets/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto Condensed Bold";
    src: local("RobotoCondensed"),
        url("../src/assets/fonts/RobotoCondensed-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto Light";
    src: local("RobotoCondensed"),
        url("../src/assets/fonts/RobotoCondensed-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto Condensed";
    src: local("RobotoCondensed"),
        url("../src/assets/fonts/RobotoCondensed-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Nunito Regular";
    src: local("NunitoRegular"),
        url("../src/assets/fonts/Nunito-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Nunito Bold";
    src: local("NunitoBold"),
        url("../src/assets/fonts/Nunito-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Nunito SemiBold";
    src: local("NunitoSemiBold"),
        url("../src/assets/fonts/Nunito-SemiBold.ttf") format("truetype");
}

* {
    margin: 0;
    padding: 0;
}

#root {
    height: 100vh;
}

:root {
    --red-plata: #fc1d25;
}

body {
    margin: 0;
    padding: 0;
    color: #000000;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:focus,
:visited,
p,
h1,
h2,
h3,
h4,
h5,
h6,
label {
    outline: none;
    border-width: 0;
    text-decoration: none;
}

a {
    width: 100%;
    color: #FFF;
    outline: none;
    border-width: 0;
    display: contents;
    text-decoration: none;
}

img {
    pointer-events: none;
    user-select: none;
}

strong {
    font-family: "Nunito Bold", sans-serif;
}

textarea {
    max-height: 100px;
}

ul,
li {
    list-style: none;
}

.red-bold {
    font-size: 16px;
    line-height: 20px;
    color: #DD141D;
    font-family: "Nunito Bold", sans-serif;
}

.chat-box {
    margin: auto;
    padding: 0;
    height: 100vh;
    overflow: hidden;
    border-radius: 0;
    background-color: #F3F5F7;
}

.pdf-button {
    border: 1px solid #002559;
    border-radius: 22px;
    background-color: #002559;
    color: #fff;
    font-size: 16px;
    padding: 7px;
    cursor: pointer;
    margin-top: 10px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Nunito Regular', sans-serif;
}

.float-version {
    z-index: 3;
    position: fixed;
    bottom: 0px;
    right: 5px;
    border-radius: 50px;
    text-align: center;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    display: block
}

::-webkit-scrollbar-thumb {
    background: #e4eaf5;
    border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(196, 201, 211)
}