.container {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Nunito Regular', sans-serif;
}

.header {
    width: 100%;
    margin-top: 16px;
    margin-left: 16px;
    align-items: flex-start;
}

.logo-header {
    width: 93px;
    height: 40px;
}

.content {
    max-width: 311px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    color: #002559;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
}

.logo {
    width: 118px;
    height: 100px;
}

.subtitle {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
}

.form-container {
    gap: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.form-label {
    color: #002559;
    font-size: 14px;
    line-height: 20px;
}

.form-input {
    height: 44px;
    font-size: 16px;
    line-height: 20px;
    padding: 0px 16px;
    border-radius: 24px;
    caret-color: #01C5C6;
    border: 1px solid #F6F9FC;
    background-color: #F6F9FC;
    font-family: 'Nunito Regular', sans-serif;
}

.form-input:focus {
    caret-color: #01C5C6;
    border: 1px solid #01C5C6;
}

.form-input::placeholder {
    color: #C1C1C1 !important;
    font-family: 'Nunito Regular', sans-serif;
}

.button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.continue-button {
    width: 100%;
    height: 40px;
    color: #FFFFFF;
    border-radius: 22px;
    font-size: 16px;
    line-height: 20px;
    background-color: #89898B;
    border: 1px solid #89898B;
    box-shadow: 0px 3px 6px #00000029;
    transition: background-color border 0.3s ease-in-out;
}

.send {
    background-color: #002559;
    border: 1px solid #002559;
}

.disabled {
    background-color: #89898B;
    border: 1px solid #89898B;
}

.terms-container {
    height: 38px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.terms-checkbox {
    width: 20px;
    height: 20px;
    appearance: none;
    border: 1px solid #89898B;
    border-radius: 6px;
    transition: background-color 0.3s ease-in-out;
}

.terms-checkbox:checked {
    background-image: url('../../assets/icons/checkbox_check.svg');
    border: 1px solid #002559;
    background-color: #002559;
}

.terms-container p {
    color: #89898B;
    font-size: 14px;
}

.terms-paragraph {
    max-width: 90%;
    margin-left: 10.5px;
}

.terms-label {
    color: #002559;
}