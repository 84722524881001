.tooltip {
    position: relative;
    width: 24px;
    margin-left: 10px;
}

.tooltip img {
    margin-top: 4px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.tooltip .tooltiptext {
    visibility: hidden;
    background-color: #000000;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 12px;
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 16px;
    line-height: 19px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 300;
    width: 187px;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    border-width: 6px;
    border-style: solid;
}

.tooltip.top .tooltiptext {
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
}

.tooltip.top .tooltiptext::after {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-color: #000000 transparent transparent transparent;
}

.tooltip.right .tooltiptext {
    top: 150%;
    left: 125%;
    transform: translateY(-50%);
}

.tooltip.right .tooltiptext::after {
    top: 20%;
    left: -10px;
    transform: translateY(-50%);
    border-color: transparent #000000 transparent transparent;
}

.tooltip.bottom .tooltiptext {
    top: 125%;
    left: 50%;
    transform: translateX(-50%);
}

.tooltip.bottom .tooltiptext::after {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-color: transparent transparent #000000 transparent;
}

.tooltip.left .tooltiptext {
    top: 50%;
    right: 125%;
    transform: translateY(-50%);
}

.tooltip.left .tooltiptext::after {
    top: 50%;
    right: -6px;
    transform: translateY(-50%);
    border-color: transparent transparent transparent #000000;
}
