.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.label {
    text-align: right;
    font-size: 16px;
    line-height: 20px;
    font-family: "Ubuntu", sans-serif;
}

.arrow {
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px
}

.back {
    cursor: pointer;
    background-image: url("../../assets/icons/mdi-mensual-ig1.svg");
}

.next {
    cursor: pointer;
    background-image: url("../../assets/icons/mdi-mensual-rg1.svg");
}

.disabled-back {
    background-image: url("../../assets/icons/mdi-mensual-ig2.svg");
}

.disabled-next {
    background-image: url("../../assets/icons/mdi-mensual-rg2.svg");
}