.container {
    top: 0;
    z-index: 2;
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(22, 22, 22, .5);
}

.card {
    width: 327px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
}

.popup-message {
    color: #000000;
    padding: 0px 24px;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    font-family: "Roboto Condensed", sans-serif;
}

.inactive-card {
    width: 327px;
    height: 234px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
}