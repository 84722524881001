.navbar {
    width: 100%;
    height: 56px;
    box-sizing: border-box;
    background-color: #002559;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 4px #00000033;
    border-radius: 0px 0px 16px 16px;
}

.title {
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    font-family: 'Nunito Bold', sans-serif;
}

.subtitle {
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    font-family: 'Nunito Regular', sans-serif;
}

.back {
    left: 16px;
    position: fixed;
}

.actions {
    right: 16px;
    position: fixed;
    gap: 16px;
    display: flex;
    flex-direction: row;
}

.icons {
    width: 24px;
    height: 24px;
}

.avatar {
    width: 36px;
    height: 36px;
}

.info-container {
    gap: 8px;
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #FFFFFF;
    font-family: 'Nunito Regular', sans-serif;
}

.info {
    gap: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.verified {
    width: 16px;
    height: 16px;
}