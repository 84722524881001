.response-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buttons-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4px;
}

.image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: transparent;
}